<template>
	<div class="px-4 w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<div>
			<a-tabs size="large" default-active-key="1">
				<div slot="tabBarExtraContent" class="dF aC" style="gap: 25px">
					<div v-if="corporateInstance" class="dF aC" style="gap: 5px">
						<span>Projects:</span>
						<SelectProjects class="ml-2" />
					</div>
					<div class="dF aC" style="gap: 5px">
						<span>Print:</span>
						<a @click.prevent="downloadPDF" href="javascript:;" class="text-primary">
							Charts
						</a>
						<PDFOrientation class="ml-2" />
					</div>
					<div class="dF aC" style="gap: 5px">
						<span>Download:</span>
						<a @click.prevent="getCSV" href="javascript:;" class="text-primary">CSV</a>
					</div>

					<DatePicker @current-times-update="fetchChartData($event, 'currentData')"
						:disabled-compare-date="true" />
				</div>
				<a-tab-pane key="1" tab="Overview">
					<vue-html2pdf :show-layout="true" :float-layout="false" :enable-download="false" :preview-modal="false"
						pdf-content-width="100%" :manual-pagination="true" :html-to-pdf-options="{
							margin: pdfOrientation === 'portrait' ? [50.2, 10, 50.2, 10] : [10, 50, 5, 50],
							image: { type: 'jpeg', quality: 2 },
							enableLinks: true,
							html2canvas: { scale: 1, useCORS: true },
							jsPDF: {
								orientation: pdfOrientation
							}
						}" @beforeDownload="beforeDownload($event)" ref="html2Pdf">
						<section slot="pdf-content">
							<div v-if="!error" class="report-grid">
								<ChartCard class="html2pdf__page-break" :details="leadsOverTime" :loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="leadSource" :loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="leadToSalesOpportunity" :loading="loading"
									@mode-change="
										leadToSalesOpportunityOption = $event
										" />
								<ChartCard class="html2pdf__page-break" :details="salesLeadVelocityRate"
									:loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="leadByReferrerSource" :loading="loading"
									@type-change="leadByReferrerSourceChartType = $event" />
								<ChartCard class="html2pdf__page-break" :details="leadByCampaign" :loading="loading"
									@type-change="leadByCampaignChartType = $event" />
							</div>
							<div v-else class="mt-10" style="text-align: center">
								<h4>Something went wrong. Please Try again!</h4>
								<a-button type="primary" @click="fetchChartData">Retry</a-button>
							</div>
						</section>
					</vue-html2pdf>
				</a-tab-pane>
			</a-tabs>
		</div>
	</div>
</template>

<script>
import ChartCard from "@/components/charts/chartCard";
import chartData from "./chartData";
import DatePicker from "@/components/charts/datePicker.vue";
import PDFOrientation from "@/components/charts/pdfOrientation.vue";
import SelectProjects from "@/components/charts/selectProjects.vue";
import { mapState, mapMutations } from "vuex";
import VueHtml2pdf from "vue-html2pdf";
import moment from "moment";
import { formatDate } from 'bh-mod'

export default {
	components: {
		ChartCard,
		DatePicker,
		VueHtml2pdf,
		PDFOrientation,
		SelectProjects
	},
	data() {
		return {
			loading: false,
			error: false,
			leadReport: {
				currentData: {},
				compareData: {},
			},
			leadToSalesOpportunityOption: "countDetails",
			leadByReferrerTypeChartType: 'bar',
			leadByReferrerSourceChartType: 'bar',
			leadByCampaignChartType: 'bar',
			chartTitles: [
				'Leads over time',
				'Lead source',
				'Marketing lead to sales opportunity',
				'Lead velocity rate',
				'Lead by Referrer Type',
				'Lead by Referrer Source',
				'Lead by Campaigns'
			],
			leadTrafficData: {}
		}
	},

	computed: {
		...mapState([
			"currentStartDate",
			"currentEndDate",
			"compareStartDate",
			"compareEndDate",
			"records",
			"instance"
		]),

		pdfOrientation() {
			return this.$store.state.pdfOrientation
		},

		corporateInstance() {
			return (this.instance && this.instance.projectType === 'corporate' && this.instance.children.length)
		},

		leadsOverTime() {
			const chartDetails = chartData.leadsOverTime();
			if (this.corporateInstance) {
				chartDetails.option.chartColumn = 'stack'
			}
			return {
				...chartDetails,
				chartDetails: this.getChartDetails("leadsOverTime"),
			};
		},

		leadSource() {
			const chartDetails = chartData.leadSource();
			if (this.corporateInstance) {
				chartDetails.option.chartColumn = 'stack'
				delete chartDetails.option.colorBy
			}
			return {
				...chartDetails,
				chartDetails: this.getChartDetails("leadSources"),
			};
		},

		leadToSalesOpportunity() {
			let chartDetails = chartData.leadToSalesOpportunity();
			chartDetails = { ...chartDetails[this.leadToSalesOpportunityOption] }
			if (this.corporateInstance) {
				chartDetails.option.chartColumn = 'stack'
			}
			return {
				...chartDetails,
				chartDetails: this.getChartDetails("leadToSalesOpportunity"),
			};
		},

		salesLeadVelocityRate() {
			const chartDetails = chartData.salesLeadVelocityRate();
			if (this.corporateInstance) {
				chartDetails.option.chartColumn = 'stack'
			}
			const result = {
				...chartDetails,
				chartDetails: this.getChartDetails(
					"salesLeadVelocityRate"
				),
			};

			const currentMonth = moment(this.currentEndDate).endOf("month").format("MMMM YYYY");
			const previousMonth = moment(this.currentEndDate).subtract(1, "month").startOf("month").format("MMMM YYYY");

			const data = result.chartDetails && result.chartDetails.length && result.chartDetails[0].data;

			if (data && data[currentMonth]) {
				if (data[currentMonth] === data[previousMonth]) {
					result.totalAmount = 0;
				} else if (data[previousMonth]) {
					result.totalAmount = ((data[currentMonth] - data[previousMonth]) / data[previousMonth]) * 100;
				} else {
					result.totalAmount = 100;
				}
			}

			return result;
		},

		leadByReferrerType() {
			const chartDetails = chartData.leadByReferrerType();
			chartDetails.option.chartType = this.leadByReferrerTypeChartType;
			return {
				...chartDetails,
				chartDetails: this.getLeadTrafficChartDetails("trafficByReferrerType"),
			};
		},

		leadByReferrerSource() {
			const chartDetails = chartData.leadByReferrerSource();
			chartDetails.option.chartType = this.leadByReferrerSourceChartType;
			return {
				...chartDetails,
				chartDetails: this.getLeadTrafficChartDetails("trafficByReferrer"),
			};
		},

		leadByCampaign() {
			const chartDetails = chartData.leadByCampaign();
			chartDetails.option.chartType = this.leadByCampaignChartType;
			return {
				...chartDetails,
				chartDetails: this.getLeadTrafficChartDetails("trafficByCampaign"),
			};
		},

		filename() {
			return `Lead Report(${moment(this.currentStartDate).format(
				"ll"
			)} - ${moment(this.currentEndDate).format("ll")})`;
		},

		leadRecords() {
			let records = [...this.records.lead.all]
			return records.map((l) => {
				return {
					...l,
					leadCreatedAt: l.leadCreatedAt && formatDate(l.leadCreatedAt) || "",
					lastInteraction: l.lastInteraction && formatDate(l.lastInteraction) || "",
				}
			})
		}
	},

	methods: {
		...mapMutations(["setCurrentDates", "setCompareDates", "setRecords"]),

		getLeadTrafficChartDetails(chartType) {
			const chartDetails = [];
			const data = {};
			if (this.leadTrafficData[chartType]) {
				this.leadTrafficData[chartType].forEach(c => {
					if (chartType === 'trafficByReferrer') {
						data[c.referrer] = {}
						if (!data[c.referrer]) data[c.referrer] = {};

						c.sources.forEach(s => {
							data[c.referrer][s.source] = s.count;
						})
					} else {
						data[c.type || c.name || c.source] = c.count
					}
				})
			}

			chartDetails.push({
				startDate: this.currentStartDate,
				endDate: this.currentEndDate,
				data: data,
			});

			return chartDetails;
		},

		getChartDetails(chartType) {
			const chartDetails = [];
			if (this.leadReport.currentData[chartType]) {
				chartDetails.push({
					startDate: this.currentStartDate,
					endDate: this.currentEndDate,
					data: this.leadReport.currentData[chartType],
				});
			}

			if (this.leadReport.compareData[chartType]) {
				chartDetails.push({
					startDate: this.compareStartDate,
					endDate: this.compareEndDate,
					data: this.leadReport.compareData[chartType],
				});
			}
			return chartDetails;
		},

		fetchChartData(
			{
				startDate = this.currentStartDate,
				endDate = this.currentEndDate,
			},
			dateType = "currentData"
		) {
			this.error = false;
			this.loading = true;

			if (dateType === "currentData") {
				this.setCurrentDates({ startDate, endDate });
			} else {
				this.setCompareDates({ startDate, endDate });
			}

			if (!startDate && !endDate) {
				this.leadReport[dateType] = {};
				this.loading = false;
				this.$forceUpdate();
				return;
			}

			this.fetchLeadReportData(startDate, endDate);

			this.$api
				.post(`/daily-reports/${this.$store.state.instance.id}/graph`, {
					type: "lead",
					start: startDate,
					end: endDate,
				})
				.then(({ data }) => {
					let sources = [];
					if (data && data.records && data.records.all && data.records.all.length) {
						data.records.all.forEach((r) => {
							if (r.leadSource && !sources.includes(r.leadSource)) {
								sources.push(r.leadSource);
							}
						});
					}
					data.records.sources = sources;
					this.setRecords({
						records: data.records,
						type: "lead",
					});
					delete data.records;
					this.leadReport[dateType] = data;
					this.loading = false;
				})
				.catch((error) => {
					console.error(error);
					this.error = true;
					this.loading = false;
				});
		},

		async fetchLeadReportData(startDate, endDate) {
			try {
				let promises = [this.$api.get(`traffic-reports/:instance/referrer-report?start=${startDate}&end=${endDate}`), this.$api.get(`traffic-reports/:instance/campaign-report?start=${startDate}&end=${endDate}`)]
				const result = await Promise.all(promises)
				this.leadTrafficData = {};
				result.forEach(r => {
					if (r.data) {
						Object.keys(r.data).forEach(key => {
							this.leadTrafficData[key] = r.data[key]
						})
					}
				})
				this.setRecords({
					records: result && result[1] && result[1].data && result[1].data.trafficByCampaign || [],
					type: "campaigns",
				});
			} catch (err) {
				console.error(err);
			}
		},

		downloadPDF() {
			this.$nprogress.start();
			this.$refs.html2Pdf.generatePdf();
		},

		async beforeDownload({ html2pdf, options, pdfContent }) {
			options.filename = this.filename;
			await html2pdf()
				.set(options)
				.from(pdfContent)
				.toPdf()
				.get("pdf")
				.then((pdf) => {
					const totalPages = pdf.internal.getNumberOfPages();
					for (let i = 1; i <= totalPages; i++) {
						pdf.setPage(i);
						pdf.setFontSize(11);
						pdf.text(
							"Page " + i,
							pdf.internal.pageSize.getWidth() * 0.88,
							10
						);

						pdf.setFontSize(16);

						const title = this.chartTitles[i - 1] || ''
						pdf.text(
							title,
							(pdf.internal.pageSize.getWidth() * 0.44) - title.length,
							(this.pdfOrientation === 'portrait' ? 25 : 10)
						);
					}
				})
				.save()
				.then(() => {
					this.$nprogress.done();
				})
				.catch(() => {
					this.$nprogress.done();
				});
		},

		getCSV() {
			if (this.leadRecords.length) {
				let csv = Object.keys(this.leadRecords[0]).join(",");
				csv += "\n";

				this.leadRecords.forEach((row) => {
					csv += Object.values(row)
						.map((r) => {
							if (typeof r === "string") {
								return r.replaceAll(", ", " & ");
							}
							return r;
						})
						.join(",");
					csv += "\n";
				});

				const anchor = document.createElement("a");
				anchor.href =
					"data:text/csv;charset=utf-8," + encodeURIComponent(csv);
				anchor.target = "_blank";
				anchor.download = `${this.filename}.csv`;
				anchor.click();
			} else {
				this.$message.info("No records found!");
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.report-grid {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 20px;

	@media screen and (max-width: 1279px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
}

.ant-select-selection__placeholder {
	color: var(--primary);
}
</style>
